.px-btn {
  padding: 13px 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 2px solid var(--px-theme);
  background-color: var(--px-theme);
  color: var(--px-white);
  text-decoration: none;
  transition: ease all 0.35s;
  font-weight: 500;
  outline: none;
  box-shadow: none;
  text-transform: uppercase;
  cursor: pointer;
  i {
    margin-left: 10px;
  }
  &:hover {
    background-color: var(--px-black);
    color: var(--px-white);
    border: 2px solid var(--px-white);
  }
  &.dark {
    background: var(--px-black);
    border: 1px solid var(--px-black);
    color: var(--px-white);
    &:hover {
      background: var(--px-white);
      color: var(--px-black);
    }
  }
  &.light {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: var(--px-white);
    &:hover {
      background: var(--px-white);
      color: var(--px-black);
    }
  }
  &.white {
    background: var(--px-white);
    border: 1px solid var(--px-white);
    color: var(--px-black);
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.15);
      color: var(--px-white);
    }
  }
}

.px-btn.extended {
  padding: 13px 210px;
}

.social-icon {
  a {
    width: 46px;
    height: 46px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--px-white);
    border: 2px solid var(--px-white);
    margin-right: 8px;
    position: relative;
    transition: ease top 0.35s;
    top: 0;
    &:hover {
      top: -2px;
    }
    &.instagram {
      background: #f26798;
    }
    &.whatsapp {
      background: #25D366;
    }
    &.facebook {
      background: #1877f2;
    }
    &.linkedin {
      background: #1275b1;
    }
  }
}
